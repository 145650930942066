import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AccountsService } from './api/accounts.service';
import { AccountsPayableService } from './api/accountsPayable.service';
import { AgmService } from './api/agm.service';
import { AuthenticationService } from './api/authentication.service';
import { BankService } from './api/bank.service';
import { BudgetService } from './api/budget.service';
import { ClientExperienceService } from './api/clientExperience.service';
import { CompanyService } from './api/company.service';
import { CreditorService } from './api/creditor.service';
import { DebtorService } from './api/debtor.service';
import { DocumentService } from './api/document.service';
import { EmailService } from './api/email.service';
import { ExchangeService } from './api/exchange.service';
import { IncomingCallService } from './api/incomingCall.service';
import { InsuranceService } from './api/insurance.service';
import { NoteService } from './api/note.service';
import { OwnerService } from './api/owner.service';
import { PortfolioService } from './api/portfolio.service';
import { ReportService } from './api/report.service';
import { SearchService } from './api/search.service';
import { SmsService } from './api/sms.service';
import { StatusReportService } from './api/statusReport.service';
import { StrataService } from './api/strata.service';
import { SystemService } from './api/system.service';
import { UnitService } from './api/unit.service';
import { UserService } from './api/user.service';
import { UserReportService } from './api/userReport.service';
import { UsersService } from './api/users.service';
import { UtilService } from './api/util.service';
import { WhiteFolderService } from './api/whiteFolder.service';
import { WorkflowService } from './api/workflow.service';
import { WorkorderService } from './api/workorder.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AccountsService,
    AccountsPayableService,
    AgmService,
    AuthenticationService,
    BankService,
    BudgetService,
    ClientExperienceService,
    CompanyService,
    CreditorService,
    DebtorService,
    DocumentService,
    EmailService,
    ExchangeService,
    IncomingCallService,
    InsuranceService,
    NoteService,
    OwnerService,
    PortfolioService,
    ReportService,
    SearchService,
    SmsService,
    StatusReportService,
    StrataService,
    SystemService,
    UnitService,
    UserService,
    UserReportService,
    UsersService,
    UtilService,
    WhiteFolderService,
    WorkflowService,
    WorkorderService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
